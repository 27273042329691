import React from "react"

import Hls from "hls.js"
import "../css/video.css"

export default class Video extends React.Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
  }

  componentDidMount() {
    var playbackId = this.props.playback_id
    var url = this.props.playback_id
      ? "https://stream.mux.com/" + playbackId + ".m3u8"
      : this.props.url

    var video = this.video.current

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = url
      video.addEventListener("loadedmetadata", function () {
        video.play()
      })
    } else if (Hls.isSupported()) {
      var hls = new Hls()
      hls.loadSource(url)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play()
      })
    }
  }

  componentWillUnmount() {
    this.video.current.pause()
  }

  render() {
    return (
      <video
        ref={this.video}
        muted
        loop
        playsInline
        autoPlay
        controlsList="nodownload"
        disablePictureInPicture
        preload="none"
        poster={`https://image.mux.com/${this.props.playback_id}/thumbnail.jpg?time=0`}
        className="Video"
      ></video>
    )
  }
}
