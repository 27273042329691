import React, { useState } from "react"

import "../css/accordion.css"

export default function Accordion(props) {
  const [open, setOpen] = useState(false)

  let dl = (values, indexOffset) => (
    <dl>
      {values.map((a, i) => {
        return (
          <div
            key={i}
            className={
              open === indexOffset + i ? "Accordion__open" : "Accordion__closed"
            }
          >
            <dt>
              <button
                onClick={e => {
                  e.preventDefault()
                  setOpen(open === indexOffset + i ? false : indexOffset + i)
                }}
              >
                {a.title}
              </button>
            </dt>
            <dd>{a.content}</dd>
          </div>
        )
      })}
    </dl>
  )

  return (
    <div className="Accordion">
      <div>{dl(props.values.slice(0, 7), 0)}</div>
      <div>{dl(props.values.slice(7), 7)}</div>
    </div>
  )
}
