import React from "react"
import Layout from "../layout"
import "../css/about.css"
import "../css/features.css"
import "../css/header.css"

import Accordion from "../components/Accordion"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Video from "../components/Video"
import videos from "../util/videos"

export default function Features() {
  // TODO: does this load the right sizes; e.g. 100% of container or 100vw of container?
  const images = useStaticQuery(graphql`
    {
      entrance: file(relativePath: { eq: "features/entrance@2x.jpg" }) {
        ...jpgFragment
      }

      capacity1: file(relativePath: { eq: "features/capacity-1@2x.jpg" }) {
        ...jpgFragment
      }

      capacity2: file(relativePath: { eq: "features/capacity-2@2x.jpg" }) {
        ...jpgFragment
      }

      capacity3: file(relativePath: { eq: "features/capacity-3@2x.jpg" }) {
        ...jpgFragment
      }

      capacity4: file(relativePath: { eq: "features/capacity-4.jpg" }) {
        ...jpgFragment
      }

      seating: file(relativePath: { eq: "features/seating@2x.jpg" }) {
        ...jpgFragment
      }

      accordionLightboxes: file(
        relativePath: { eq: "features/accordion/Light boxes above bar.jpg" }
      ) {
        ...tinyFragment
      }

      roomDividers1: file(
        relativePath: { eq: "features/accordion/Room Dividers-8976.jpg" }
      ) {
        ...tinyFragment
      }

      roomDividers2: file(
        relativePath: { eq: "features/accordion/Room Dividers-8979.jpg" }
      ) {
        ...tinyFragment
      }

      brandingSignage1: file(
        relativePath: {
          eq: "features/accordion/Quantcast Nova Toronto 2018-3.jpg"
        }
      ) {
        ...tinyFragment
      }

      brandingSignage2: file(
        relativePath: { eq: "features/accordion/Think Insights 2013 - 004.jpg" }
      ) {
        ...tinyFragment
      }

      displayStations: file(
        relativePath: { eq: "features/accordion/BlankVenue-9021.jpg" }
      ) {
        ...tinyFragment
      }

      welcomeArea: file(
        relativePath: { eq: "features/accordion/Blank Venue-9008.jpg" }
      ) {
        ...tinyFragment
      }
    }
  `)

  const accordion = [
    {
      title: "Dedicated Venue Specialist + Event Staff",
      content: (
        <p>
          Event staff include bartenders, security, wait staff and coat check
          staff (if required) during your event. Your personal venue specialist
          builds your custom menu and bar plan, creates onsite timeline and
          floorplan, accepts deliveries, arranges site visits with any necessary
          external vendors and manages your event execution on the date of your
          event.
        </p>
      ),
    },
    {
      title: "Customizable Lightboxes",
      content: (
        <>
          <p>
            Giving you the option to add custom graphics, a company logo, family
            photos, or event décor concept.
          </p>
          <figure>
            <Img fluid={images.accordionLightboxes.childImageSharp.fluid} />
          </figure>
        </>
      ),
    },
    {
      title: "Automated Room Dividers",
      content: (
        <>
          <p>
            Creating multiple layout options within a single event and space,
            room dividers change with the click of a button. They allow for room
            reveals and space partitions, providing the option of more intimate
            initial meetings to open up the space later in your event.
          </p>
          <figure>
            <Img fluid={images.roomDividers1.childImageSharp.fluid} />
          </figure>
          <figure>
            <Img fluid={images.roomDividers2.childImageSharp.fluid} />
          </figure>
        </>
      ),
    },
    {
      title: "Servicewares",
      content: (
        <p>
          In-house glassware, plateware and cutlery (with options to upgrade).
        </p>
      ),
    },
    {
      title: "Branding and Signage Opportunities",
      content: (
        <>
          <p>
            Customizable entrance marquee, personalized entrance lights, blank
            walls for custom decal installations (optional).
          </p>
          <figure>
            <Img fluid={images.brandingSignage1.childImageSharp.fluid} />
          </figure>
          <figure>
            <Img fluid={images.brandingSignage2.childImageSharp.fluid} />
          </figure>
        </>
      ),
    },
    {
      title: "Bridal Suite/Green Room",
      content: <p>With lock, private washroom, and sitting area.</p>,
    },
    {
      title: "Built-In Food/Display Stations",
      content: (
        <>
          <p>
            Facilitating the service of menu items for cocktail-style weddings,
            meetings or communal dessert/snack areas. Also available for product
            displays or branding.
          </p>
          <figure>
            <Img fluid={images.displayStations.childImageSharp.fluid} />
          </figure>
        </>
      ),
    },
    {
      title: "Built-In Welcome Area",
      content: (
        <>
          <p>
            Station for sign-in or guest book, coat check, display, or area for
            guests to take parting gift upon leaving.
          </p>
          <figure>
            <Img fluid={images.welcomeArea.childImageSharp.fluid} />
          </figure>
        </>
      ),
    },
    {
      title: "Furniture",
      content: (
        <p>
          A combination of 9 rectangle, 9 round tables, and 12 custom high-top
          tables, 140 grey bellini chairs, a selection of 5 lounge seats.
          Upgrade options available.
        </p>
      ),
    },
    {
      title: "WiFi",
      content: (
        <p>
          Available for all guests, with the option of upgrading to premium
          packages for live streaming.
        </p>
      ),
    },
    {
      title: "In-House Catering",
      content: (
        <p>
          Curated by Executive Chef Jeff Dueck with a custom event menu.
          Catering options include cocktail style receptions, seated meals,
          grab-and-go breakfast and lunch meetings, passed late night snacks,
          kids menu options and more. Take a peek at our{" "}
          <Link to="/food-drink">Food and Drink</Link> page for more.
        </p>
      ),
    },
    {
      title: "36 Foot Bar",
      content: (
        <p>
          Package options include open bar, host tab, tickets, cash bar or open
          soft bar. Take a peek at our{" "}
          <Link to="/food-drink">Food and Drink</Link> page for more.
        </p>
      ),
    },
    {
      title: "A/V Equipment",
      content: (
        <>
          <p>
            In-house sound system, wireless microphone, projector, screen and
            built-in DJ booth with free use of in-house Pioneer DJM-700 mixer
            and two Pioneer CDJ-900s.
          </p>
          <p>
            <small>*Technician and DJ not included.</small>
          </p>
        </>
      ),
    },
  ]

  return (
    <Layout>
      <header className="Header Header--image">
        <h1>Features</h1>
        <figure>
          <Img fluid={images.entrance.childImageSharp.fluid} />
        </figure>
      </header>
      <p className="Section__intro">
        From sit-down meetings to warehouse-style gatherings, weddings, award
        ceremonies, screenings, cocktail receptions and more,
        Second&nbsp;Floor&nbsp;Events welcomes both half and full-day bookings
        all year round.
      </p>
      <section className="Floater Floater--capacity">
        <figure>
          <Img fluid={images.capacity3.childImageSharp.fluid} />
        </figure>
        <h2>Capacity</h2>
        <dl className="Capacity">
          <div>
            <dt>180</dt>
            <dd>Seated Dinner</dd>
          </div>
          <div>
            <dt>350</dt>
            <dd>Cocktail Style</dd>
          </div>
          <div>
            <dt>215</dt>
            <dd>Theater Style</dd>
          </div>
        </dl>
        <p className="Footnote">
          We are happy to accommodate intimate events, please connect with us
          for options and quotes.
        </p>
        <figure>
          <Img fluid={images.capacity1.childImageSharp.fluid} />
        </figure>
        <figure>
          <Img fluid={images.capacity2.childImageSharp.fluid} />
        </figure>
      </section>

      <section>
        <figure>
          <Img fluid={images.capacity4.childImageSharp.fluid} />
        </figure>
      </section>

      <section className="Features">
        <hr />
        <p>
          Second&nbsp;Floor&nbsp;Events provides multiple in-house services to
          save you time coordinating with many external vendors. These services
          include:
        </p>
        <div className="Features__accordion">
          <h2>Features</h2>
          <Accordion values={accordion} />
          <h2>Services</h2>
        </div>
        <div class="CTA">
          <Link to="/contact" className="Button">
            Book Now
          </Link>
        </div>
      </section>

      <div class="VideoLoop">
        <Video playback_id={videos.features} />
      </div>

      <section className="FloorPlans">
        <div>
          <h2>Floor Plans</h2>
          <div>
            <p>
              Download any of our floorpans below. Have a question?{" "}
              <Link to="/contact">Let’s chat.</Link>
            </p>
            <ul className="Buttons">
              <li>
                <a
                  href="/floor-plans/2FL Floor Plan - 50 Seats (Harvest).pdf"
                  target="_blank"
                  className="Button Button--transparent"
                >
                  50 Seated
                </a>
              </li>
              <li>
                <a
                  href="/floor-plans/2FL Floor Plan - 100 Seats.pdf"
                  target="_blank"
                  className="Button Button--white"
                >
                  100 Seated
                </a>
              </li>
              <li>
                <a
                  href="/floor-plans/2FL Floor Plan - 180 Seated.pdf"
                  target="_blank"
                  className="Button Button--white"
                >
                  180 Seated
                </a>
              </li>
              <li>
                <a
                  href="/floor-plans/2FL Floor Plan - Ceremony.pdf"
                  target="_blank"
                  className="Button Button--white"
                >
                  Ceremony
                </a>
              </li>
              <li>
                <a
                  href="/floor-plans/2FL Floor Plan - Blank.pdf"
                  target="_blank"
                  className="Button Button--white"
                >
                  Blank Venue
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <figure>
            <Img fluid={images.seating.childImageSharp.fluid} />
          </figure>
        </div>
      </section>
    </Layout>
  )
}
